import React from "react"
import HeaderSubCenter from "../components/header_sub_center"
import LayoutInner from "../components/layout_inner"
import LayoutOuter from "../components/layout_outer"

export default function PageNotFound() {
  return (
    <LayoutOuter>
      <LayoutInner>
        <div className="py-16 text-center">
          <HeaderSubCenter
            title="Not found"
            subtitle="Oops! looks like the page you are looking for has been removed or relocated."
          />
        </div>
      </LayoutInner>
    </LayoutOuter>
  )
}
